<template>
  <div class="wrap">
    <div class="bg"></div>
    <main class="main">
      <div class="img-box">
        <div class="view-1" :class="{active: active === 1}" @mouseenter="onEnter(1)"></div>
        <div class="view-2" :class="{active: active === 2}" @mouseenter="onEnter(2)"></div>
        <div class="view-3" :class="{active: active === 3}" @mouseenter="onEnter(3)"></div>
      </div>
      <div class="info">
        <div class="title">橙子剧场</div>
        <div class="description">橙子剧场是一款资源丰富的追剧神器，全站短剧从第一集看到最后一集，剧情紧凑，精彩不断；各类短剧应有尽有，高质量原创内容，带给你不一样的追剧体验；</div>
        <img src="/img/qr-code-chengzi.png" alt="qrcode">
      </div>
    </main>
    <p class="business-code">ICP备案：桂ICP备2022005297号-1</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 2
    }
  },
  created() {},
  methods: {
    onEnter(active) {
      this.active = active
    }
  }
}
</script>

<style lang='scss' scoped>
.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.bg {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/img/screen.jpg');
  background-size: cover;
  filter: blur(3px);
}
.main {
  position: relative;
  z-index: 2;
  width: 1200px;
  height: 500px;
  display: flex;
  justify-content: space-between;
  .img-box {
    width: 250px;
    height: 542px;
    position: relative;
    > * {
      position: absolute;
      width: 250px;
      height: 542px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 15px;
      transition: 0.5s;
      cursor: pointer;
    }
  }
  .view-1 {
    z-index: 5;
    background-image: url('/img/view4.png');
    transform: scale(1);
  }
  .view-2 {
    z-index: 6;
    left: 60%;
    background-image: url('/img/view5.png');
    transform: scale(1);
  }
  .view-3 {
    z-index: 5;
    left: 120%;
    background-image: url('/img/view6.png');
    transform: scale(1);
  }
  .active {
    z-index: 10;
    transform: scale(1.2);
  }
}
.info {
  width: 460px;
  color: #fff;
  .title {
    font-size: 34px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .description {
    font-weight: bold;
    font-size: 17px;
    line-height: 28px;
  }
  /* .img-box {
    display: flex;
  } */
  img {
    display: block;
    width: 190px;
    height: 190px;
    margin: 80px auto 0;
  }
}
.business-code {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  color: #333;
  z-index: 100;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
}
</style>